import React from 'react'
import './footer.css'
import { GoArrowUp } from "react-icons/go";
import { FaReact, FaHeart } from 'react-icons/fa'

function Footer() {
    return (
        <div id="contacts">
            <div className='contacts'>
                <h3>Let's connect</h3>
                <div className='flex-row'>
                    <p><a target='_blank' rel="noreferrer" href='https://www.linkedin.com/in/andrea-troccolo' alt="Linkedin profile">Linkedin</a></p>
                    <p><a target='_blank' rel="noreferrer" href='https://www.github.com/troccoloAndrea' alt="Github profile">GitHub</a></p>
                    <p><a target='_blank' rel="noreferrer" href='https://www.instagram.com/andreatroccolo/' alt="Instagram profile">Instagram</a></p>
                    <p><a alt='Email' href='mailto:troccoloandrea01@gmail.com'>troccoloandrea01@gmail.com</a></p>
                </div>
            </div>
            <div className='flex-row copyright'>
                <p>Made with <FaHeart /> and <FaReact className='react-icon' /></p>
                <a href='#top'><GoArrowUp /> Back to top</a>
                <p>© 2024 Andrea Troccolo</p>
            </div>
                {/* <div className='lighthouse'>
                    <div>
                        <span className='mes'>lighthouse report</span> <span className='score'>11/12/2023</span>
                    </div>
                    <div>
                        <span className='mes'>Perfomance</span> <span className='score'>98%</span>
                    </div>
                    <div>
                        <span className='mes'>Accessibility</span> <span className='score'>100%</span>
                    </div>
                    <div>
                        <span className='mes'>Best Practice</span> <span className='score'>100%</span>
                    </div>
                    <div>
                        <span className='mes'>SEO</span> <span className='score'>100%</span>
                    </div>
                </div> */}
        </div>
    )
}

export default Footer