import React from 'react'
import './project.css'
import { GoArrowUpRight } from "react-icons/go";

function Project({ title, description, year, type, role, partners, link }) {
    return (
        <div className='project'>
            <div className='project-details'>
                <span>{type}</span>
                <p className='title'>{title}</p>
                <p>{description}</p>
                <div className='project-info'>
                    <div className='info'>
                        <span>YEAR</span>
                        <span>{year}</span>
                    </div>
                    <div className='info'>
                        <span>ROLE</span>
                        <span>{role}</span>
                    </div>
                    {partners ?
                    <div className='info'>
                        <span>PARTNERS</span>
                         {partners.map((p, k) => <img key={k} width="auto" height={24} src={p.image} alt={p.alt} className='partner-logo'  />) }
                    </div>
                    : <></>}
                </div>
                {link ? <a target='_blank' rel="noreferrer" href={link} alt={title + " info"}>Go to this project <GoArrowUpRight /></a> :<></>}
            </div>
            <div className='spacer'></div>
        </div>
    )
}

export default Project