import React from 'react'
import './navbar.css'

function Navbar() {
    return (
        <nav id="top" className="navbar">
            <a className="navbar-brand" href="#top">Andrea</a>
                <div className="navbar-nav">
                    <a className="nav-link" aria-current="page" href="#about">about</a>
                    <a className="nav-link" href="#portfolio">portfolio</a>
                    <a className="nav-link" href="#contacts">contacts</a>
                </div>
        </nav>
    )
}

export default Navbar