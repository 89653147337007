import React from 'react'
import './hero.css'
import MyNavbar from '../../components/navbar/Navbar'
import { motion } from "framer-motion";
import { GoArrowDown } from "react-icons/go";

function Hero() {


    const firstLineTransition = { circIn: [0.180, 0.375, 0.190, -0.320], delay: 0.2, duration: 1.5, type: 'spring' }
    const secondLineTransition = { circIn: [0.180, 0.375, 0.190, -0.320], delay: 0.3, duration: 1.5, type: 'spring' }

    const titleVariants = {
        hidden: { y: 120 },
        visible: { y: 0 }
    }

    return (
        <div className='hero section'>
            <MyNavbar />
            <div className='hero-content'>
                <div className='hero-line'>
                    <div className='hero-title'>
                        <div className='line'>
                            <motion.div variants={titleVariants} initial="hidden" animate="visible" transition={firstLineTransition} className='line-text'>
                                <h1>Andrea Troccolo</h1>
                            </motion.div>
                        </div>
                        <div className='line'>
                            <motion.div variants={titleVariants} initial="hidden" animate="visible" transition={secondLineTransition} className='line-text'>
                                <h1>CS Student</h1>
                            </motion.div>
                        </div>
                    </div>
                </div>
                <div className='hero-line'>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ ease: "easeIn", duration: 0.5, delay: 0.8 }}
                        variants={{type: 'spring'}}>
                        <p>Hi, i'm Andrea, 22yo cs student based in Milan, Italy. <br />Currently i'm at the third year of bachelor's degrees in Computer Science at University of Milan.</p>
                    </motion.div>
                </div>
                <motion.div variants={{ hidden: { y: -5, opacity: 0 }, visible: { y: 0, opacity: 1 }, type: 'spring' }} initial="hidden" animate="visible" transition={{ ease: "easeIn", delay: 1.2, duration: 0.3 }} className='hero-line'>
                    <span></span>
                    <motion.div variants={{ type: 'spring' }} animate={{ y: [0, 5, 0] }} transition={{ duration: 2, repeat: Infinity, repeatDelay: 1, delay: 2.2 }}>
                        <a href='#about' className='hero-link' >about me <GoArrowDown /></a>
                    </motion.div>
                </motion.div>
            </div>
        </div>

    )
}

export default Hero