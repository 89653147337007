import About from "./section/about/About";
import Hero from "./section/hero/Hero";
import Portfolio from "./section/portfolio/Portfolio";
import Footer from "./components/footer/Footer";
import React from 'react';

function App() {
  return (
    <div className="scroll-container">
      <div className="box-container">
        <Hero />
        <About />
        <Portfolio />
        <Footer />
      </div>
    </div>

  );
}

export default App;
