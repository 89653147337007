import React from 'react'
import './portfolio.css'
import Project from '../../components/project/Project'
import polimi from '../../img/poli-design1.webp'
import fondazione from '../../img/fondazione-polimi.webp'
import micron from '../../img/micron.webp'
import s2i from '../../img/start2impact.webp'
import { GoArrowUpRight } from "react-icons/go";


function Portfolio() {

  const projects = [
    {
      title: "L'Adda di Leonardo",
      description: "Multiplatform application for mobile devices for the use of textual, visual and audiovisual contents concerning the route of the river Adda and the works of Leonardo da Vinci.",
      year: "2020",
      role: "Lead Full-stack developer",
      type: "School project",
      partners: [{ alt: "Politecnico di Milano, Dipartimento di Design", image: polimi },
      { alt: "Micron SPA", image: micron },
      { alt: "Fondazione Politecnico di Milano", image: fondazione }],
      link: "https://www.ecomuseoaddadileonardo.it/micron/"
    },
    {
      title: "Coloured tiles on a Cartesian plane",
      description: "Modelling and implementation of floor tiles using a graph and related data structures. Synthesis, implementation, and analysis of algorithms. Project implemented in Go.",
      year: "2024",
      role: "Analyst & Developer",
      type: "University project",
      partners: undefined,
      link: undefined
    },
    {
      title: "myUnimia",
      description: "Web application built in php and postgresql database for managing university exams and staff.",
      year: "2024",
      role: "Full-stack developer & DBA",
      type: "University project",
      partners: undefined,
      link: undefined
    },
    {
      title: "Social Network for Music",
      description: "Web application built in react to search music tracks via Spotify API and organise them into public or private playlists.",
      year: "2023",
      role: "Full-stack developer",
      type: "University project",
      partners: undefined,
      link: undefined
    },
    {
      title: "Pollution.info",
      description: "Web application to find out the level of air pollution of a specific city or by current location. The application was developed in Javascript using the waqi api.",
      year: "2021",
      role: "Front-end developer",
      type: "Front-end development master project",
      partners: [{ alt: "Start2Impact University", image: s2i }],
      link: "https://compassionate-lalande-eb32ad.netlify.app/"
    }
  ]

  return (
    <>
      <div id="portfolio" className='portfolio section'>
        <h2>Portfolio</h2>
        <p>Collection of my works, find more on my <a target='_blank' rel="noreferrer" alt="Github Profile" href='https://github.com/troccoloAndrea'>GitHub <GoArrowUpRight /></a></p>
        {
          projects.map((v, k) => (
            <Project
              key={k}
              title={v.title}
              description={v.description}
              year={v.year}
              role={v.role}
              partners={v.partners}
              link={v.link}
              type={v.type} />
          ))
        }
      </div>
      
    </>
  )
}

export default Portfolio