import React from 'react'
import { GoArrowDown } from "react-icons/go";
import './about.css'

function About() {
    return (
        <div id="about" className='about section'>
            <div className='text-box'>
                <div className='left-col'>
                    <span>(about me)</span>
                    <a href='#portfolio'><GoArrowDown /> my portfolio</a>
                </div>
                <p>My name is Andrea, and I live in Bergamo, near Milan. I've owned a computer since I was five years old, which sparked a strong passion for the world of computers and technology.
                    <br /><br />
                    Since I began delving into the realm of computer science, I found my path in full-stack development.
                    <br />
                    As I advance in my studies, I am acquiring skills such as algorithm synthesis and analysis, data structures, database management, software engineering, and computer networks.</p>
                <div className='spacer'></div>
            </div>
        </div>
    )
}

export default About